import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";

export const ServiceContainer = styled.div`
  color: #fff;
  background: #fff;
  width: 100%;
  height: 100%;
  min-height: 1px;
`;

export const ServiceWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row" : "row-reverse")};
  min-height: 1px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  margin: 20px;
  padding: 16px;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

export const TopLine = styled.p`
  color: #fdbe34;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.1.%;
  font-weight: 600;
  color: #0d226b;

  @media screen and (max-width: 768px) {
    font-size: 32px;
    text-align: center;
  }
`;

export const DescText = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #373838;
  margin-top: 12px;
`;

export const ImageContainer = styled.div`
  margin: 20px;
  width: 50%;
  height: 100%;
  min-height: 1px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 555px;
  min-height: 1px;
`;

export const Image = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 28px;
`;

export const BtnWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const LinkButton = styled(Link)`
  border-radius: 5px;
  background: transparent;
  border: 2px solid #fdbe34;
  white-space: nowrap;
  padding: 10px 48px;
  margin: 12px;
  text-align: center;
  font-size: 16px;
  color: #0d226b;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  :hover {
    background: #0d226b;
    color: #fff;
    border-color: #0d226b;
  }
`;

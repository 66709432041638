import React from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { StyleArray } from "./styles";

const containerStyle = {
  position: "absolute",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
  borderRadius: "4px",
  overflow: 'hidden',
};

const MapComponent = (props) => {

  return (
    <div style={containerStyle}>
      <Map
        google={props.google}
        styles={StyleArray}
        zoom={15}
        initialCenter={{
          lat: props.lat,
          lng: props.lng
        }}
        center={{
          lat: props.lat,
          lng: props.lng
        }}
      >
        <Marker
          title={props.name}
          name={props.name}
          position={{ 
            lat: props.lat,
            lng: props.lng, 
          }}
        />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC0Kny_lVNMRMX8f-vKCJjMtWSQiKQngsc",
})(MapComponent);

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import {
  ServiceContainer,
  ServiceWrapper,
  TextContainer,
  TextWrapper,
  Heading,
  Subtitle,
  ImageContainer,
  ImageWrapper,
  Image,
} from "./content.elements";

const Content = ({ index, title, points, images, alt }) => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      offset: 0,
      disable: "mobile",
    });
  }, []);

  return (
    <ServiceContainer
      className={`container-${title}`}
      id={index}
      data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
    >
      <ServiceWrapper id={index}>
        <ImageContainer
          data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
          data-aos-delay="400"
          data-aos-anchor={`.container-${title}`}
        >
          <ImageWrapper>
            <Image src={images} alt={alt} />
          </ImageWrapper>
        </ImageContainer>
        <TextContainer
          data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
          data-aos-delay="200"
          data-aos-anchor={`.container-${title}`}
        >
          <TextWrapper>
            <Heading>{title}</Heading>
            <Subtitle>{points}</Subtitle>
          </TextWrapper>
        </TextContainer>
      </ServiceWrapper>
    </ServiceContainer>
  );
};

export default Content;

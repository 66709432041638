import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import {
  SectionContainer,
  SectionWrapper,
} from "../../components/common.elements";

import Banner from "../../components/banner";
import AccessContent from "../../components/access/access_content";

import { OfficeData } from "../../data/access_data";

import AccessBannerImage from "../../images/banner/banner-access.jpg";
import SmallNav from "../../components/navigation/smallnav";

const AccessBannerInfo = {
  header: "アクセス",
  subheader: [
    "物流を業する者の本分に徹し、",
    "お客様の価値創造の一役を担うことを使命とする",
  ],
  image: AccessBannerImage,
  alt: "AccessBannerImage",
};

const _OfficeData = OfficeData;
const AccessNavInfo = _OfficeData.map((office, index) => {
  return {
    linkname: office.shortname,
    urlpath: office.id,
  };
});

const Access = ({ match }) => {
  const { url } = useRouteMatch();
  const data = OfficeData;
  const default_url = url.concat("/1");

  return (
    <div>
      <Banner content={AccessBannerInfo} />
      <SmallNav data={AccessNavInfo} />
      <SectionContainer>
        <SectionWrapper>
          <Switch>
            <Route path={`${url}/:buildingId`}>
              <AccessContent data={data} default_url={default_url} />
            </Route>
            <Route exact path={`${url}/`}>
              <Redirect to={{ pathname: `${url}/1` }} />
            </Route>
          </Switch>
        </SectionWrapper>
      </SectionContainer>
    </div>
  );
};

export default Access;

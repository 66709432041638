import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const SmallFooterContainer = styled.div`
  display: none;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    display: flex;
  }
`;

export const FooterButton = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #101522;
  border-bottom: 1px solid #f2f7ff;
  font-weight: bold;
  color: #fbfbfb;
  width: 100%;
  padding: 24px 20px;
  cursor: pointer;
  text-decoration: none;
`;

export const FooterIcon = styled.div`
  height: 16px;
  width: 16px;
`;

import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Navbar from "./components/navigation/navbar";
import TopBar from "./components/navigation/topbar";
import Footer from "./components/navigation/footer";
import SmallFooter from "./components/navigation/smallfooter";
import CopyRight from "./components/navigation/copyright";

import Home from "./pages";
import Company from "./pages/Company";
import Services from "./pages/Services";
import Access from "./pages/Access";
import Recruit from "./pages/Recruit";
import Contact from "./pages/Contact";
import NotFound from "./pages/notfound";

import ScrollIntoView from "./utility/ScrollIntoView";

import "./App.css";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="App">
      <ScrollIntoView />
      <TopBar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/company">
          <Company />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/access">
          <Access />
        </Route>
        <Route path="/recruit">
          <Recruit />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/index">
          <Redirect to="/" />
        </Route>
        <Route component={NotFound} />
      </Switch>
      <SmallFooter />
      <Footer />
      <CopyRight />
    </div>
  );
};

export default App;

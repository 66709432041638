import HeroVideo from "../../videos/herovideo.mp4";

export const HeroData = {
  title: ["迅速に、", "安全と、", "安心を。"],
  text: [
    "あらゆる温度帯の商品、食材を適温で適切に配送する仕組みを構築しました。",
    "２４時間、３６５日体制で迅速に対応いたします。",
  ],
  video: HeroVideo,
  alt: "test",
};

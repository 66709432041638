import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Banner from "../../components/banner";
import RecruitHeader from "../../components/recruit/recruit_header";
// import RecruitInfoContainer from "../../components/recruit/recruit_info_container";
// import RecruitContent from "../../components/recruit/recruit_content";

// import { RecruitData } from "../../data/recruit_data";
import RecruitBannerImage from "../../images/banner/banner-recruit.jpg";

const RecruitBannerInfo = {
  header: "採用情報",
  subheader: ["当社と共に、", "素敵なライフプランを作りましょう！"],
  image: RecruitBannerImage,
  alt: "RecruitBannerImage",
};

const Recruit = () => {
  const { path } = useRouteMatch();
  // const data = RecruitData;

  return (
    <div>
      <Banner content={RecruitBannerInfo} />
      <Switch>
        <Route exact path={`${path}/`}>
          <RecruitHeader />
          {/* <RecruitInfoContainer data={data} /> */}
        </Route>
        {/* <Route path={`${path}/:id`}>
          <RecruitContent data={data} />
        </Route> */}
      </Switch>
    </div>
  );
};

export default Recruit;

export const CompanyEthicData = {
  motto: "迅速明快",
  ethic: [
    "物流を業する者の本分に徹し、",
    "お客様の価値創造の一役を担うことを使命とする",
  ],
  CI: [
    "来るべき国際化社会、情報化社会を見据え、外国の方にも",
    "親しみやすい印象を持っていただくために、カタカナ表記とし",
    "一般的な「運送会社」からの脱却を意味する、",
    "やわらかく、一本筋の通った太めの書体としました。",
  ],
};

export const CompanyCredoData = {
  description: [
    "『クレド』とは、ラテン語で『志・約束・信条』を意味する言葉で、",
    "『企業全体の従業員が心掛けるべき信条や行動指針』のことをいいます。",
    "当社では、従業員全員が意見を出し合い、平成２９年１月に",
    "クレドを策定いたしました。",
  ],
  cr_client: {
    header: ["お客様へ", "の対応"],
    points: [
      {
        title: '"安心"を運ぶため',
        desc:
          '私達は、コンプライアンスを徹底し、荷物とともに"安心"を運びます。',
      },
      {
        title: '３つの"S"(セーフティ・スピーディ・スマイル)',
        desc: "私たちは、安全な作業を迅速に行い、お客様と笑顔を共有します。",
      },
      {
        title: 'お客様の言葉は"宝"です',
        desc: "私達は、お客様のニーズを先読みし、新たなサービスを提案します。",
      },
    ],
  },
  cr_growth: {
    header: ["メンバーの", "自律・成長"],
    points: [
      {
        title: "「考動」します",
        desc:
          "私達は、自己の立場、役割を理解し、何を求められているか考えて、積極的に行動します。",
      },
      {
        title: "常に創意工夫",
        desc: "私達は、常に向上心を持って工夫する事を楽しみます。",
      },
      {
        title: "日々成長しよう",
        desc:
          "私達は、常に目標を少し高めに設定し、自己のスキルアップに努めます。",
      },
    ],
  },
  cr_work: {
    header: ["仕事の", "取り組み方"],
    points: [
      {
        title: "生産性の追求",
        desc: "私達は、原価意識を持ち、常に生産性を追求します。",
      },
      {
        title: "作業精度の探求",
        desc:
          "私達は、常に最善を尽くし、最高の向こう側（１歩前へ）を目指します",
      },
      {
        title: "安全作業・安全運転",
        desc:
          "私達は、初心を忘れず、慣れに陥ることなく作業に細心を注意を払います。",
      },
      {
        title: "備えよ常に",
        desc: "私達は、起こりうる事態に想像力を働かせ備えます。",
      },
    ],
  },
  cr_team: {
    header: ["チームワーク", "・人村育成"],
    points: [
      {
        title: "チーム力",
        desc:
          "私達は、個々の作業枠にとらわれず、チーム全体の進捗状況を客観的にとらえ、横断的に協力し合い業務の遂行を目指します。",
      },
      {
        title: "元気な挨拶",
        desc: "私達は、誰にでも気持ちの良い挨拶をします。",
      },
      {
        title: "多様な意見の尊重",
        desc:
          "私達は、人の意見や考えを安易に否定するのではなく、意見の多様性を尊重します。",
      },
      {
        title: "家族・お客様・地域社会",
        desc:
          "私達は、大切な家族・お客様・地域社会のより良い未来を作ることに貢献します。",
      },
    ],
  },
};

import React from "react";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../components/common.elements";
import Content from "../../components/service/content";
import { DeliveryServiceData } from "../../data/service/deliveryData";

const Delivery = () => {
  const content = DeliveryServiceData.map((service, index) => {
    return <Content key={index} index={index} {...service} />;
  });

  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitle>運送・配送</SectionTitle>
        <TitleDivider />
        {content}
      </SectionWrapper>
    </SectionContainer>
  );
};

export default Delivery;

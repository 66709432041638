import React from "react";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../components/common.elements";
import Content from "../../components/service/content";
import { WarehouseServiceData } from "../../data/service/warehouseData";

const Warehouse = () => {
  const content = WarehouseServiceData.map((service, index) => {
    return <Content key={index} index={index} {...service} />;
  });

  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitle>商品管理</SectionTitle>
        <TitleDivider />
        {content}
      </SectionWrapper>
    </SectionContainer>
  );
};

export default Warehouse;

import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const SSNavContainer = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  background: #f2f7ff;
  position: sticky;
  top: 60px;
  box-shadow: 0px 2px 10px 3px rgba(164, 158, 158, 0.3);
  z-index: 6;

  @media screen and (max-width: 600px) {
    box-shadow: none;
    border-bottom: 4px solid white;
  }
`;

export const SSNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 640px;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

export const SSNavLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  font-size: 16px;
  text-align: center;
  text-decoration: none;
  color: #373738;

  cursor: pointer;

  :hover {
    font-size: 18px;
    font-weight: bold;
  }

  &.active {
    color: #0d226b;
    font-size: 18px;
    font-weight: bold;

    .arrow {
      color: #fdbe34;
    }
  }

  @media screen and (max-width: 600px) {
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;

    &.active {
      background-color: #0d226b;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      border: none;
    }
  }
`;

export const ArrowIcon = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin: 0px 2px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const CompanyHistoryData = [
  {
    year: 1975,
    month: 8,
    event: "有限会社宮城運輸設立 ",
  },
  {
    year: 1996,
    month: "",
    event: "本社を現在地に移転",
  },
  {
    year: 2009,
    month: 1,
    event: "安全性優良事業所認定証(Gマーク)取得(本社)",
  },
  {
    year: 2009,
    month: 5,
    event: "ISO9001：2008取得(本社)",
  },
  {
    year: 2009,
    month: 8,
    event: "茨城営業所　開設",
  },
  {
    year: 2010,
    month: 8,
    event: "志和センター　開設",
  },
  {
    year: 2012,
    month: 7,
    event: "小山営業所　開設",
  },
  {
    year: 2015,
    month: 7,
    event: "グリーン経営認証　取得",
  },
  {
    year: 2015,
    month: 12,
    event: "安全性優良事業所認定証(Gマーク)取得(茨城営業所)",
  },
  {
    year: 2016,
    month: 6,
    event: "ISO39001:2012取得(本社)",
  },
  {
    year: 2016,
    month: 6,
    event: "臨空センター開設",
  },
  {
    year: 2016,
    month: 12,
    event: "安全性優良事業所認定証(Gマーク)取得(小山営業所)",
  },
  {
    year: 2018,
    month: 4,
    event: "ISO9001:2015へ更新(本社・臨空センター)",
  },
  {
    year: 2021,
    month: 4,
    event: "ロゴマークのカタカナ形式に変更実施",
  },
  {
    year: 2021,
    month: 12,
    event: "安全性優良事業所認定証(Gマーク)取得(志和営業所)",
  },
];

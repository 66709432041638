import React from "react";
import Pdf1 from "../../../resources/koudoukikaku-ippan-2022.pdf";
import Pdf2 from "../../../resources/koudoukikaku-josei-2022.pdf";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../common.elements";

import {
  OutlineTable,
  TableItem,
  OutlineLabel,
  OutlineValue,
} from "./outline.elements";

const OutlineInfo = ({
  name,
  chairman,
  president,
  address,
  telephone,
  fax,
  establishDate,
  capital,
  nEmployee,
  bank,
  business,
}) => {
  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitle>会社概要</SectionTitle>
        <TitleDivider />
        <OutlineTable>
          <TableItem>
            <OutlineLabel>会社名</OutlineLabel>
            <OutlineValue>{name}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>代表取締役会長</OutlineLabel>
            <OutlineValue>{chairman}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>取締役社長</OutlineLabel>
            <OutlineValue>{president}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>本社住所地</OutlineLabel>
            <OutlineValue>{address}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>代表番号</OutlineLabel>
            <OutlineValue>{telephone}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>代表FAX</OutlineLabel>
            <OutlineValue>{fax}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>設立</OutlineLabel>
            <OutlineValue>{establishDate}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>資本金</OutlineLabel>
            <OutlineValue>{capital}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>取引銀行</OutlineLabel>
            <OutlineValue>
              <ul style={{ paddingLeft: "16px" }}>
                {bank.map((bankName, index) => {
                  return (
                    <li key={index}>
                      <span style={{ position: "relative", left: "8px" }}>
                        {bankName}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>従業員数</OutlineLabel>
            <OutlineValue>{nEmployee}</OutlineValue>
          </TableItem>
          <TableItem>
            <OutlineLabel>事業内容</OutlineLabel>
            <OutlineValue>
              <ul style={{ paddingLeft: "16px" }}>
                {business.map((service, index) => {
                  return (
                    <li key={index}>
                      <span style={{ position: "relative", left: "8px" }}>
                        {service}
                      </span>
                    </li>
                  );
                })}
                <li>
                  <span style={{ position: "relative", left: "8px" }}>
                    <a href={Pdf1} target="_blank" rel="noopener noreferrer">
                      一般事業主行動計画
                    </a>{" "}
                    (※クリックで別ファイルが表示されます)
                  </span>
                </li>
                <li>
                  <span style={{ position: "relative", left: "8px" }}>
                    <a href={Pdf2} target="_blank" rel="noopener noreferrer">
                      女性活躍行動計画
                    </a>{" "}
                    (※クリックで別ファイルが表示されます)
                  </span>
                </li>
              </ul>
            </OutlineValue>
          </TableItem>
        </OutlineTable>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default OutlineInfo;

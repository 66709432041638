import styled from "styled-components";

export const Narration = styled.div`
  text-align: center;
  line-height: 175%;
  margin-bottom: 0px;
  color: #373738;

  span {
    display: inline-block;
  }
`;

export const NarrationTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin: 32px 0px 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 12px;
  }
`;

export const TableHeader = styled.div`
  width: 100%;
  padding: 6px 0px;
  background-color: #0d226b;
  color: #fdbe34;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #0d226b;
`;

export const TableCell = styled.div`
  padding: 12px;
  background: #fbfbfb;
  width: 70%;

  span {
    display: inline-block;
  }
`;

export const TableVal = styled.div`
  /* border: thick solid blue; */
  background: #fbfbfb;
  padding: 12px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    display: inline-block;
  }
`;

import styled from "styled-components";
import { Link } from "react-router-dom";

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccordionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #101522;
  border-bottom: 1px solid #f2f7ff;
  font-weight: bold;
  color: #fbfbfb;
  width: 100%;
  padding: 24px 20px;
  cursor: pointer;
`;

export const AccordionContent = styled.div`
  background: #f2f7ff;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? "300px" : "0px")};
  display: flex;
  flex-direction: column;
`;

export const AccordionItem = styled(Link)`
  padding: 16px 24px;
  text-decoration: none;
  color: black;
  cursor: pointer;
`;

export const ArrowIcon = styled.div`
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "")};
  transition: all 0.2s ease-in-out;
  height: 16px;
  width: 16px;
`;

import React, { useEffect, useRef, useState } from "react";

import {
  SliderContainer,
  SliderWrapper,
  Slide,
  ImageWrapper,
  SlideImage,
  PageWrapper,
  Dot,
} from "./slider.elements";

const Slider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [hovered, setHovered] = useState(false);
  const length = slides.length;
  const timeout = useRef(null);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    };
    if (!hovered) {
      timeout.current = setTimeout(nextSlide, 3000);
    }

    return () => {
      if (timeout.current || hovered) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length, hovered]);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  const handleClick = (index) => {
    setCurrent(index);
  };

  return (
    <SliderContainer>
      <SliderWrapper>
        {slides.map((slide, index) => {
          return (
            <Slide
              active={index === current}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              key={index}
            >
              <ImageWrapper>
                <SlideImage src={slide} />
              </ImageWrapper>
            </Slide>
          );
        })}
      </SliderWrapper>
      <PageWrapper>
        {slides.map((slide, index) => {
          return (
            <Dot
              active={index === current}
              key={index}
              onClick={() => handleClick(index)}
            />
          );
        })}
      </PageWrapper>
    </SliderContainer>
  );
};

export default Slider;

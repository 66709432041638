import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { FaFax, FaPhone } from "react-icons/fa";

import { SectionTitle, SectionSubtitle } from "../../common.elements";

import {
  OfficeContainer,
  TitleContainer,
  SmallContentContainer,
  InfoTitle,
  SubInfoTitle,
  MapContainer,
  InfoContainer,
  SubInfoContainer,
  CustomContainer,
  OfficeName,
  Description,
  InfoRow,
  InfoLabel,
  InfoContent,
  ContactItem,
  IconContainer,
} from "./access_content.elements";

import {
  CustomIconContainer,
  NumberContainer,
  TypeContainer,
} from "../custom_icon/custom_icon.elements";

import MapComponent from "../../map";

const SplitArray = (data, n) => {
  if (n < 2) return [data];

  var len = data.length;
  var out = [];
  var i = 0;
  var size;

  if (len % n === 0) {
    size = Math.floor(len / n);
    while (i < len) {
      out.push(data.slice(i, (i += size)));
    }
  } else {
    n--;
    size = Math.floor(len / n);
    if (len % size === 0) size--;
    while (i < size * n) {
      out.push(data.slice(i, (i += size)));
    }
    out.push(data.slice(size * n));
  }

  return out;
};

const showBuildingName = (name) => {
  if (name.indexOf("(") !== -1) {
    let splitted = name.split("(");
    let office = splitted[0];
    let center = "(" + splitted[1];
    return (
      <>
        <span style={{ display: "inline-block", marginRight: "5px" }}>
          {office}
        </span>
        <span style={{ display: "inline-block" }}>{center}</span>
      </>
    );
  } else {
    return name;
  }
};

const AccessContent = ({ data, default_url }) => {
  const { buildingId } = useParams();
  const building = data.find((p) => p.id === Number(buildingId));

  const BuildingInfo = (data) => {
    let nRows = Math.ceil(data.length / 3);
    let dataArray = SplitArray(data, nRows);

    return dataArray.map((rows, index) => {
      return (
        <CustomContainer key={index}>
          {rows.map((item, index) => {
            return (
              <CustomIconContainer key={index}>
                <NumberContainer size="24">
                  {item.count}m<sup>2</sup>
                </NumberContainer>
                <TypeContainer>{item.name}</TypeContainer>
              </CustomIconContainer>
            );
          })}
        </CustomContainer>
      );
    });
  };

  let officeContent;
  if (building) {
    officeContent = (
      <>
        <SmallContentContainer>
          <TitleContainer>
            <SectionTitle>{showBuildingName(building.name)}</SectionTitle>
            <SectionSubtitle>{building.location}</SectionSubtitle>
          </TitleContainer>
          <InfoContainer>
            <SubInfoContainer>
              <Description>
                {building.description.map((sentence, index) => {
                  return <span id={index}>{sentence}</span>;
                })}
              </Description>
            </SubInfoContainer>
          </InfoContainer>
          {building.vehicles.length !== 0 && (
            <SubInfoContainer>
              <InfoTitle>保有車両</InfoTitle>
              <CustomContainer>
                {building.vehicles.map((vehicle, index) => {
                  return (
                    <CustomIconContainer key={index}>
                      <NumberContainer size="36">
                        {vehicle.count}台
                      </NumberContainer>
                      <TypeContainer>{vehicle.name}</TypeContainer>
                    </CustomIconContainer>
                  );
                })}
              </CustomContainer>
            </SubInfoContainer>
          )}
          {building.spaces.length !== 0 && (
            <SubInfoContainer>
              <InfoTitle>使用スペース</InfoTitle>
              {BuildingInfo(building.spaces)}
            </SubInfoContainer>
          )}
          <SubInfoContainer>
            <InfoTitle>アクセス</InfoTitle>
          </SubInfoContainer>
        </SmallContentContainer>
        <OfficeContainer>
          <MapContainer>
            <MapComponent
              lat={building.lat}
              lng={building.lng}
              name={building.name}
            />
          </MapContainer>
          <InfoContainer>
            <SubInfoContainer type="big">
              <OfficeName> {showBuildingName(building.name)} </OfficeName>
              <Description> {building.description} </Description>
            </SubInfoContainer>
            {building.vehicles.length !== 0 && (
              <SubInfoContainer type="big">
                <SubInfoTitle>保有車両</SubInfoTitle>
                {building.vehicles.map((vehicle, index) => {
                  return (
                    <InfoRow key={index}>
                      <InfoLabel>{vehicle.name}</InfoLabel>
                      <InfoContent>{vehicle.count}台</InfoContent>
                    </InfoRow>
                  );
                })}
              </SubInfoContainer>
            )}
            {building.spaces.length !== 0 && (
              <SubInfoContainer type="big">
                <SubInfoTitle>使用スペース</SubInfoTitle>
                {building.spaces.map((space, index) => {
                  return (
                    <InfoRow key={index}>
                      <InfoLabel>{space.name}</InfoLabel>
                      <InfoContent>
                        {space.count}m<sup>2</sup>
                      </InfoContent>
                    </InfoRow>
                  );
                })}
              </SubInfoContainer>
            )}
            <SubInfoContainer>
              <SubInfoTitle>住所</SubInfoTitle>
              <InfoContent>〒 {building.postcode}</InfoContent>
              <InfoContent>{building.address}</InfoContent>
            </SubInfoContainer>
            <SubInfoContainer>
              <SubInfoTitle>連絡先</SubInfoTitle>
              <ContactItem>
                <IconContainer>
                  <FaPhone />
                </IconContainer>
                <InfoContent>{building.tel}</InfoContent>
              </ContactItem>
              <ContactItem>
                <IconContainer>
                  <FaFax />
                </IconContainer>
                <InfoContent>{building.fax}</InfoContent>
              </ContactItem>
            </SubInfoContainer>
          </InfoContainer>
        </OfficeContainer>
      </>
    );
  } else {
    officeContent = <Redirect to={{ pathname: default_url }} />;
  }

  return <div>{officeContent}</div>;
};

export default AccessContent;

// {building.spaces.map((space, index) => {
//   return (
//     <CustomIconContainer key={index}>
//       <NumberContainer size="24">
//         {space.count}m<sup>2</sup>
//       </NumberContainer>
//       <TypeContainer>{space.name}</TypeContainer>
//     </CustomIconContainer>
//   );
// })}

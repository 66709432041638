import React from 'react'
import CertificateList from '../../components/company/certificate'

const Certificate = () => {
  return (
    <CertificateList />
  )
}

export default Certificate

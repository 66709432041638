import React from "react";

import {
  CertContainer,
  ItemTitle,
  ImageContainer,
  CertImage,
  ItemContainer,
  InfoContainer,
  InfoItem,
  InfoLabel,
  InfoValue,
} from "./cert_item.elements";

const CertificationItem = ({
  name,
  description,
  scope,
  examiner,
  office,
  image,
  alt,
  link,
  linkname,
}) => {
  return (
    <CertContainer>
      <ItemTitle>{name}</ItemTitle>
      <ItemContainer>
        <ImageContainer>
          <CertImage src={image} alt={alt} />
        </ImageContainer>
        <InfoContainer>
          {description && (
            <InfoItem>
              <InfoValue>
                {description.map((sentence, index) => {
                  return <span id={index}>{sentence}</span>;
                })}
              </InfoValue>
            </InfoItem>
          )}
          {scope && (
            <InfoItem>
              <InfoLabel>登録範囲</InfoLabel>
              <InfoValue>{scope}</InfoValue>
            </InfoItem>
          )}
          {examiner && (
            <InfoItem>
              <InfoLabel>検査機関</InfoLabel>
              <InfoValue>{examiner}</InfoValue>
            </InfoItem>
          )}
          {office && (
            <div>
              <InfoItem>
                <InfoLabel>認証事務所</InfoLabel>
                <InfoValue>{office.join("、 ")}</InfoValue>
              </InfoItem>
            </div>
          )}
          {link && (
            <div>
              <InfoValue>
                取組内容につきましては、下記をクリックしてください：
              </InfoValue>
              <InfoValue>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {linkname}
                </a>
              </InfoValue>
            </div>
          )}
        </InfoContainer>
      </ItemContainer>
    </CertContainer>
  );
};

export default CertificationItem;

import React, { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Banner from "../../components/banner";
import ContactForm from "../../components/contact/form";
import ContactBannerImage from "../../images/banner/banner-contact.jpg";

import SuccessPage from "./success";
import FormSuccessRoute from "../../components/contact/utility/formSuccessRoute";

const ContactBannerInfo = {
  header: "お問い合わせ",
  subheader: ["お客様のご要望を", "お聞かせください"],
  image: ContactBannerImage,
  alt: "RecruitBannerImage",
};

const Contact = () => {
  const { path } = useRouteMatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }

  return (
    <div>
      <Switch>
        <Route exact path={`${path}/`}>
          {!isSubmitted ? (
            <>
              <Banner content={ContactBannerInfo} />
              <ContactForm submitForm={submitForm} />
            </>
          ) : (
            <Redirect
              to={{ pathname: `${path}/success`, acknowledged: true }}
            />
          )}
        </Route>
        <FormSuccessRoute path={`${path}/success`} component={SuccessPage} />
      </Switch>
    </div>
  );
};

export default Contact;

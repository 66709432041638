export const TRANSLATIONS_EN = {
  form_ja: "Japanese",
  form_en: "English",

  form_contact_type: "Contact type",
  form_company_name: "Company name",
  form_department: "Department name (optional)",
  form_name: "Full name",
  form_telephone: "Telephone number",
  form_mail_address: "Email address",
  form_contact_message: "Messages",

  form_placeholder_company: "××× Co.,Ltd",
  form_placeholder_department: "××× Department",
  form_placeholder_fullname: "Miyagi Tarou",
  form_placeholder_content: "Please enter your inquiry details here",

  form_opt_delivery: "Delivery request",
  form_opt_management: "Center Management",
  form_opt_3pl: "3PL",
  form_opt_warehouse: "Warehouse",
  form_opt_operation: "Warehouse Operation",
  form_opt_other: "Other",

  form_title: "Inquiry Form",
  form_submit: "Submit",
  form_loading: "Processing...",
  form_disclaimer:
    "Miyagi Transportation Co., Ltd. complies with laws and regulations regarding the protection of personal information and handles customers' personal information in accordance with our privacy policy.",

  error_no_type: "Plese select an inquiry type",
  error_no_company: "Please enter your company name",
  error_no_fullname: "Please enter your full name",
  error_no_phone: "Please enter your telephone number",
  error_no_email: "Please enter your email address",
  error_no_content: "Please enter your inquiry details",

  error_wrong_phone: "The phone number you entered is invalid",
  error_wrong_email: "The email address you entered is invalid",
};

import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

const FormSuccessRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  return (
    <Route {...rest}>
      {location.acknowledged ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: "/notfound" }} />
      )}
    </Route>
  );
};

export default FormSuccessRoute;

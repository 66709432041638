import styled from "styled-components";

export const OfficeContainer = styled.div`
  display: flex;
  max-width: 1100px;
  margin: auto;
  padding-bottom: 40px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const TitleContainer = styled.div`
  padding: 0px 20px;
`;

export const SmallContentContainer = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const InfoTitle = styled.h3`
  color: #373838;
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
`;

export const SubInfoTitle = styled.h4`
  font-weight: bold;
  color: #0b409c;
  width: 100%;
  text-align: left;
  padding-bottom: 6px;
`;

export const MapContainer = styled.div`
  display: flex;
  width: 60%;
  padding-top: 60%;
  margin: 12px;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: 320px;
    margin: 0;
    padding: 0;
  }
`;

export const InfoContainer = styled.div`
  width: 40%;
  padding: 12px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 12px;
  }
`;

export const SubInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 6px;
  width: 100%;

  @media screen and (max-width: 768px) {
    display: ${({ type }) => (type === "big" ? "none" : "block")};
  }
`;

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding: 12px;
`;

export const OfficeName = styled.h1`
  color: #0d226b;
  border-left: 5px solid #fdbe34;
  padding-left: 8px;
  align-self: left;
  font-size: 28px;
  margin-bottom: 24px;
`;

export const SmallOfficeName = styled.h1`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    align-self: center;
    font-size: 24px;
    margin: 48px 0px;
    font-weight: 400;
  }
`;

export const Description = styled.div`
  display: flex;
  color: #373838;

  span {
    display: inline-block;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const InfoLabel = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 144px;
`;

export const InfoContent = styled.div`
  display: flex;
  color: #373838;
  font-size: 16px;
  line-height: 20px;
  margin: 4px 0px;
  align-self: center;
  width: 100%;
`;

export const ContactItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  padding: 6px;
  margin-right: 6px;
`;

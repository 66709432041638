import styled from "styled-components";

export const ServiceContainer = styled.div`
  display: flex;
  color: #fff;
  width: 100%;
  background: ${({ id }) => ((id + 1) % 2 === 0 ? "#fefbf5" : "#fff")};
  min-height: 1px;

  @media screen and (max-width: 768px) {
    padding: 12px;
    background: ${({ id }) => (id % 2 === 0 ? "#fefbf5" : "#fff")};
  }
`;

export const ServiceWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ id }) => (id % 2 === 0 ? "row" : "row-reverse")};
  align-items: center;
  margin: 20px 0px;
  min-height: 1px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  padding: 24px;
  width: 50%;
  height: auto;
  max-width: 50%;
  min-height: 1px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 24px 12px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
`;

export const Image = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 50%;
  max-width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 24px 12px;
  }
`;

export const TextWrapper = styled.div``;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 1.1.%;
  font-weight: 600;
  color: #0d226b;

  @media screen and (max-width: 768px) {
    font-size: 32px;
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #373838;

  @media screen and (max-width: 480px) {
    text-align: left;
    font-size: 16px;
  }
`;

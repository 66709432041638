import React from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoImg,
  NavLogoText,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
} from "./navbar.elements";

import LogoImg from "../../../images/logo/logo-img.png";
import LogoTxt from "../../../images/logo/logo-text.png";

const Navbar = ({ toggle }) => {
  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to="/">
          <NavLogoImg src={LogoImg} alt="logo-img" />
          <NavLogoText src={LogoTxt} alt="logo-txt" />
        </NavLogo>
        <MobileIcon onClick={toggle}>
          <FaBars />
        </MobileIcon>
        <NavMenu>
          <NavItem>
            <NavLink exact={true} to="/">
              トップページ
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/company">企業情報</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/services">サービス</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/access">アクセス</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/recruit">採用情報</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/contact">お間合せ</NavLink>
          </NavItem>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;

import React from 'react'
import OutlineInfo from '../../components/company/outline'

import { CompanyOutlineData } from '../../data/company/outline_data'

const Outline = () => {
  return (
    <div>
      <OutlineInfo {...CompanyOutlineData}/>
    </div>
  )
}

export default Outline



import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;

const TextWrapper = styled.div``;

const BigText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 72px;
  font-weight: bold;
  color: #0d226b;

  span {
    color: #fdbe34;
  }
`;

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px;

  div {
    line-height: 24px;
  }
`;

const Timer = styled.span`
  font-weight: bold;
  color: #0d226b;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkButton = styled(Link)`
  margin: 12px;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #fdbe34;
  width: 144px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #0d226b;

  :hover {
    background-color: #0d226b;
    transition: all 0.3s ease-in-out;
    color: #fff;
    border: 2px solid #fff;
  }
`;

const SuccessPage = () => {
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(8);

  useEffect(() => {
    let interval = null;

    if (timeLeft === 0) {
      history.push("/");
    }

    interval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => (interval ? clearInterval(interval) : null);
  }, [history, timeLeft]);

  return (
    <Container>
      <TextWrapper>
        <BigText>
          ありがとう<span>ございます</span>
        </BigText>
        <Desc>
          <div>お問合せの申し込みが完了しました。</div>
          <div>
            <Timer>{timeLeft}</Timer> 秒でトップページに戻ります。
          </div>
        </Desc>
        <ButtonWrapper>
          <LinkButton to="/">トップページ</LinkButton>
        </ButtonWrapper>
      </TextWrapper>
    </Container>
  );
};

export default SuccessPage;

import styled from "styled-components";

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  min-height: 1px;

  @media screen and (max-width: 768px) {
    height: 300px;
  }
`;

export const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-height: 1px;
`;

export const Slide = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all 1s ease-in-out;
  min-height: 1px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease-in-out;
  min-height: 1px;
`;

export const SlideImage = styled.img`
  width: 100%;
  height: auto;
  min-height: 1px;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

export const Dot = styled.span`
  height: 16px;
  width: 16px;
  margin: 0px 4px;
  display: inline-block;
  background-color: ${(props) => (props.active ? "#fdbe34" : "#0d226b")};
  zoom: ${(props) => (props.active ? "1" : "0.8")};
  transition: all 0.6s ease-in-out;
  cursor: pointer;
`;

import React from "react";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../common.elements";

import {
  CredoTableItem,
  CredoLabel,
  CredoList,
  CredoItem,
  CredoItemTitle,
  CredoItemDesc,
  CustomBullet,
  EthicLabel,
  EthicTable,
  EthicValue,
  TableItem,
  SocialLogoItem,
  TableImg,
} from "./ethic.elements";

import {
  CompanyEthicData,
  CompanyCredoData,
} from "../../../data/company/ethic_data";

import LogoImg from "../../../images/logo/logo-img2.png";
import ServiceImg from "../../../images/company/3pl_img.jpg";

const EthicInfo = () => {
  const ethicData = CompanyEthicData;
  const credoData = CompanyCredoData;

  const credoItem = (credo) => {
    return (
      <CredoTableItem>
        <CredoLabel>
          <CustomBullet>&#9656;</CustomBullet>
          {credo.header.map((fragment, index) => {
            return <span index={index}>{fragment}</span>;
          })}
        </CredoLabel>
        <CredoList>
          {credo.points.map((listItem, index) => {
            return (
              <CredoItem index={index}>
                <CredoItemTitle>{listItem.title}</CredoItemTitle>
                <CredoItemDesc>{listItem.desc}</CredoItemDesc>
              </CredoItem>
            );
          })}
        </CredoList>
      </CredoTableItem>
    );
  };

  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitle>企業理念</SectionTitle>
        <TitleDivider />
        <EthicTable>
          <TableItem>
            <EthicLabel>社是</EthicLabel>
            <EthicValue>{ethicData.motto}</EthicValue>
          </TableItem>
          <TableItem>
            <EthicLabel>企業理念</EthicLabel>
            <EthicValue>
              {ethicData.ethic.map((sentence, index) => {
                return <span index={index}>{sentence}</span>;
              })}
            </EthicValue>
          </TableItem>
          <TableItem>
            <EthicLabel>ロゴマーク</EthicLabel>
            <SocialLogoItem src={LogoImg} alt="logo-img" />
            <EthicValue>
              {ethicData.CI.map((sentence, index) => {
                return <span index={index}>{sentence}</span>;
              })}
            </EthicValue>
          </TableItem>
          <TableItem>
            <EthicLabel>クレド</EthicLabel>
            <EthicValue>
              {credoData.description.map((sentence, index) => {
                return <span id={index}>{sentence}</span>;
              })}
            </EthicValue>
          </TableItem>
          {credoItem(credoData.cr_client)}
          {credoItem(credoData.cr_growth)}
          {credoItem(credoData.cr_work)}
          {credoItem(credoData.cr_team)}
          <TableItem>
            <EthicLabel>3PLサービス</EthicLabel>
            <TableImg src={ServiceImg} alt="3pl_img" />
          </TableItem>
        </EthicTable>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default EthicInfo;

import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Banner from "../../components/banner";
import SmallNav from "../../components/navigation/smallnav";

import Delivery from "./delivery";
import Warehouse from "./warehouse";
import Technology from "./technology";

import ServiceBannerImage from "../../images/banner/banner-services.jpg";

const ServiceBannerInfo = {
  header: "サービス",
  subheader: [
    "物流を業する者の本分に徹し、",
    "お客様の価値創造の一役を担うことを使命とする",
  ],
  image: ServiceBannerImage,
  alt: "ServiceBannerImage",
};

const ServiceNavInfo = [
  { linkname: "運送・配送", urlpath: "delivery" },
  { linkname: "商品管理", urlpath: "warehouse" },
  { linkname: "IT事業", urlpath: "it-jigyou" },
];

const Company = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Banner content={ServiceBannerInfo} />
      <SmallNav data={ServiceNavInfo} />
      <Switch>
        <Route exact path={`${path}/`}>
          <Redirect to={{ pathname: `${path}/delivery` }} />
        </Route>
        <Route path={`${path}/delivery`}>
          <Delivery />
        </Route>
        <Route path={`${path}/warehouse`}>
          <Warehouse />
        </Route>
        <Route path={`${path}/it-jigyou`}>
          <Technology />
        </Route>
      </Switch>
    </div>
  );
};

export default Company;

import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

import {
  AccordionContainer,
  AccordionButton,
  AccordionContent,
  AccordionItem,
  ArrowIcon,
} from "./accordion.elements";

const Accordion = ({ title, options }) => {
  const [clicked, setClicked] = useState(false);
  const toggleClicked = () => {
    clicked ? setClicked(false) : setClicked(true);
  };

  return (
    <AccordionContainer>
      <AccordionButton onClick={() => toggleClicked()}>
        {title}
        <ArrowIcon isOpen={clicked}>
          <FaChevronDown />
        </ArrowIcon>
      </AccordionButton>
      <AccordionContent isOpen={clicked}>
        {options.map((opt, index) => {
          return (
            <AccordionItem
              key={index}
              to={opt.path}
              value={opt.val}
              onClick={() => toggleClicked()}
            >
              {opt.val}
            </AccordionItem>
          );
        })}
      </AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;

import React from "react";
import { FaRegCaretSquareUp } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

import {
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialLogoItem,
  WebsiteRights,
  SocialIcon,
} from "./copyright.elements";

import LogoImg from "../../../images/logo/logo-img.png";
import LogoTxt from "../../../images/logo/logo-text.png";

const CopyRightBar = () => {
  const toTop = () => {
    scroll.scrollToTop();
  };

  return (
    <SocialMedia>
      <SocialMediaWrap>
        <SocialLogo to="/">
          <SocialLogoItem src={LogoImg} alt="logo-img" />
          <SocialLogoItem src={LogoTxt} alt="logo-txt" />
        </SocialLogo>
        <WebsiteRights>
          <span>株式会社宮城運輸 © {new Date().getFullYear()} </span>
          <span>&nbsp;</span>
          <span>All rights reserved.</span>
        </WebsiteRights>
        <SocialIcon onClick={toTop}>
          <FaRegCaretSquareUp />
        </SocialIcon>
      </SocialMediaWrap>
    </SocialMedia>
  );
};

export default CopyRightBar;

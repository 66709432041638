import React from "react";
import {
  Narration,
  NarrationTable,
  TableHeader,
  TableRow,
  TableCell,
  TableVal,
} from "./service_header.elements";

const ServiceHeader = () => {
  return (
    <div>
      <Narration style={{ fontSize: "24px", marginBottom: "8px" }}>
        より完成された3PLへ
      </Narration>
      <Narration>
        <span>２０２０年８月、</span>
        <span>ミヤギウンユとして</span>
        <span>新たな部署を新設</span>
      </Narration>
      <Narration>
        <span>常に最先端テクノロジーを</span>
        <span>取り入れた洗練された会社を</span>
        <span>目指し、</span>
        <span>技術者の社員を</span>
        <span>積極採用</span>
      </Narration>
      <NarrationTable>
        <TableHeader>現在メンバー</TableHeader>
        <TableRow>
          <TableCell>
            <div>
              <span>東北大大学院物理学研究科卒、</span>
              <span>元ソニーの技術者</span>
            </div>
            <div>
              <span>専門：</span>
              <span>固体物理学、</span>
              <span>記憶メディア（ストーレージ）、</span>
              <span>ディスプレイ薄膜材料研究</span>
            </div>
          </TableCell>
          <TableVal>
            <div>1名</div>
            <div>
              <span>国籍：</span>
              <span>日本</span>
            </div>
          </TableVal>
        </TableRow>
        <TableRow>
          <TableCell>
            <div>
              <span>東北大大学院情報科学研究科</span>
              <span>博士課程満期退学</span>
              <span>（専門：</span>
              <span>システム情報科学専攻）、</span>
              <span>東北大学大学</span>
              <span>院教授より</span>
              <span>紹介を受け入社</span>
            </div>
          </TableCell>
          <TableVal>
            <div>1名</div>
            <div>
              <span>国籍：</span>
              <span>インドネシア</span>
            </div>
          </TableVal>
        </TableRow>
      </NarrationTable>
    </div>
  );
};

export default ServiceHeader;

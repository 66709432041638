import React from "react";
import {
  TopbarContainer,
  Icon,
  CloseIcon,
  TopbarWrapper,
  TopbarMenu,
  TopbarLink,
} from "./topbar.elements";

const TopBar = ({ isOpen, toggle }) => {
  return (
    <TopbarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <TopbarWrapper>
        <TopbarMenu>
          <TopbarLink to="/">トップページ</TopbarLink>
          <TopbarLink to="/company">企業情報</TopbarLink>
          <TopbarLink to="/services">サービス</TopbarLink>
          <TopbarLink to="/access">アクセス</TopbarLink>
          <TopbarLink to="/recruit">採用情報</TopbarLink>
          <TopbarLink to="/contact">お問合せ</TopbarLink>
        </TopbarMenu>
      </TopbarWrapper>
    </TopbarContainer>
  );
};

export default TopBar;

import React from "react";

import HeroSection from "../components/home/hero";
import ServiceSection from "../components/home/service";
import AccessSection from "../components/home/access";

import { HeroData } from "../data/home/home_slider_data";

const Home = () => {
  return (
    <>
      {/* <HeroSection slides={SliderData} /> */}

      <HeroSection data={HeroData} />
      <ServiceSection />
      <AccessSection />
    </>
  );
};

export default Home;

import ImgSlider11 from "../../images/home/slider1/slider11.jpg";
import ImgSlider12 from "../../images/home/slider1/slider12.jpg";
import ImgSlider13 from "../../images/home/slider1/slider13.jpg";

import ImgSlider21 from "../../images/home/slider2/slider21.jpg";
import ImgSlider22 from "../../images/home/slider2/slider22.jpg";
import ImgSlider23 from "../../images/home/slider2/slider23.jpg";

export const ServiceObjectDelivery = {
  id: "delivery",
  type: "doraiba",
  lightBg: false,
  lightText: false,
  lightTextDesc: true,
  topLine: "配送・物流センター",
  headline: "全国各地のあらゆる地域への輸送・配送",
  description:
    "岩手、宮城、茨城、栃木に5つの物流センターを設けております。本社センターを拠点とし、現在、主に東北と北関東で店舗配送・幹線輸送を行っております。空港・高速道路など、センターの立地条件に配慮するなど、荷主様のお荷物を東北全域―関東間で安全・円滑に移動できるよう取り組み続けております。また、弊社の全国各地に及ぶネットワークを生かして、国内のあらゆる地域への輸送・配送のお手伝いも致します。",
  buttonLabel: "test",
  imgStart: false,
  images: [ImgSlider11, ImgSlider12, ImgSlider13],
  alt: "delivery",
  dark: true,
  primary: true,
  darkText: true,
};

export const ServiceObjectWarehouse = {
  id: "warehouse",
  type: "souko",
  lightBg: false,
  lightText: false,
  lightTextDesc: true,
  topLine: "商品管理",
  headline: "最適な環境と温度で食品の保存・管理",
  description:
    "現在は主に大手レストランチェーンに納品する食品の保管を行っております。お客様のあらゆるニーズに対応できるよう、計5つの温度帯での保管が可能です。また、最近新たにITチームを設置し、常にシステムの改良・アップデートに取り組み、業務の生産性や品質管理の向上を図っております。",
  imgStart: true,
  images: [ImgSlider21, ImgSlider22, ImgSlider23],
  alt: "warehouse",
  dark: true,
  primary: true,
  darkText: true,
};

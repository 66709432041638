import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const Nav = styled.div`
  background: #ffffff;
  height: 60px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 768) {
    transition: all 0.8s ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  z-index: 1;
  width: 100%;
  padding: 10 24px;
  max-width: 1100px;

  @media screen and (max-width: 768px) {
    transition: all 0.8s ease;
  }
`;

export const NavLogo = styled(Link)`
  color: #0d226b;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const NavLogoImg = styled.img`
  height: 32px;
  width: auto;
`;

export const NavLogoText = styled.img`
  height: 32px;
  width: auto;
  margin-left: 8px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 840px) {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    transform: translate() (-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #0d226b;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  /* margin-right: -22px; */

  @media screen and (max-width: 840px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 60px;
`;

export const NavLink = styled(Link)`
  color: #373738;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    color: #0d226b;
    font-weight: bold;
    border-bottom: 5px solid #fdbe34;
  }

  &:hover {
    border-bottom: 5px solid #fdbe34;
  }
`;

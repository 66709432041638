import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Outline from "./outline";
import History from "./history";
import Certificate from "./certificate";

import Banner from "../../components/banner";
import SmallNav from "../../components/navigation/smallnav";

import CompanyBannerImage from "../../images/banner/banner-company.jpg";
import Ethic from "./ethic";

const CompanyBannerInfo = {
  header: "企業情報",
  subheader: [
    "物流を業する者の本分に徹し、",
    "お客様の価値創造の一役を担うことを使命とする",
  ],
  image: CompanyBannerImage,
  alt: "CompanyBannerImage",
};

const CompanyNavInfo = [
  { linkname: "概要", urlpath: "outline" },
  { linkname: "沿革", urlpath: "history" },
  { linkname: "認証", urlpath: "certificate" },
  { linkname: "理念", urlpath: "ethic" },
];

const Company = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Banner content={CompanyBannerInfo} />
      <SmallNav data={CompanyNavInfo} />
      <Switch>
        <Route exact path={`${path}/`}>
          <Redirect to={{ pathname: `${path}/outline` }} />
        </Route>
        <Route path={`${path}/outline`}>
          <Outline />
        </Route>
        <Route path={`${path}/history`}>
          <History />
        </Route>
        <Route path={`${path}/certificate`}>
          <Certificate />
        </Route>
        <Route path={`${path}/ethic`}>
          <Ethic />
        </Route>
      </Switch>
    </div>
  );
};

export default Company;

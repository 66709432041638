import ImageWarehouse1 from "../../images/services/warehouse/warehouse-1.jpg";
import ImageWarehouse2 from "../../images/services/warehouse/warehouse-2.jpg";
import ImageWarehouse3 from "../../images/services/warehouse/warehouse-3.jpg";
import ImageWarehouse4 from "../../images/services/warehouse/warehouse-4.jpg";
import ImageWarehouse5 from "../../images/services/warehouse/warehouse-5.jpg";
import ImageWarehouse6 from "../../images/services/warehouse/warehouse-6.jpg";

export const WarehouseServiceData = [
  {
    title: "入出庫管理業務",
    points:
      "365日、全ての入庫、出庫商品の鮮度管理を行っております。商品違い・数量違い・品質保持期限の超過のチェックを徹底しております。",
    images: ImageWarehouse1,
    alt: "warehouse",
  },
  {
    title: "仕分け業務",
    points:
      "DAS(デジタルアソートシステム)を使用し、仕分けミスが出ない様に確実な仕分けを行っております。",
    images: ImageWarehouse2,
    alt: "warehouse",
  },
  {
    title: "保管・管理業務",
    points:
      "WMSを使用し、鮮度管理、ロケーション管理を徹底し、荷主様に安心していただける管理体制を取っております。",
    images: ImageWarehouse3,
    alt: "warehouse",
  },
  {
    title: "流通加工",
    points:
      "お客様の商品を弊社にて加工し、流通させる仕組みを構築しております。",
    images: ImageWarehouse4,
    alt: "warehouse",
  },
  {
    title: "温度管理",
    points:
      "食材の温度管理を確実にするため、24時間冷蔵庫内の各場所での温度を記録しております。適温適所で管理、保管しております。",
    images: ImageWarehouse5,
    alt: "warehouse",
  },
  {
    title: "五温度帯対応",
    points:
      "倉庫保管、入出庫管理等も受託しております。商品管理・在庫システムで五温度帯の商品、食材の保管を徹底しております。",
    images: ImageWarehouse6,
    alt: "warehouse",
  },
];

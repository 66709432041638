import React from "react";

import {
  BannerContainer,
  BannerWrapper,
  BannerImage,
  BannerOverlay,
  BannerContent,
  ContentContainer,
} from "./banner.elements";

const Banner = ({ content }) => {
  return (
    <BannerContainer>
      <BannerWrapper>
        <BannerImage src={content.image} alt={content.alt} />
        <BannerOverlay />
        <ContentContainer>
          <BannerContent>
            <h1>{content.header}</h1>
            {content.subheader.map((lines, index) => {
              return <div key={index}>{lines}</div>;
            })}
          </BannerContent>
        </ContentContainer>
      </BannerWrapper>
    </BannerContainer>
  );
};

export default Banner;

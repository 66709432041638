import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.bgColor || "#fff"};
  flex-direction: row;
`;

export const SectionWrapper = styled.div`
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 40px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    padding: 40px 0px;
  }
`;

export const SectionTitle = styled.h1`
  margin-bottom: 16px;
  color: #0d226b;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
`;

export const SectionSubtitle = styled.h3`
  margin-bottom: 40px;
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const TitleDivider = styled.div`
  width: 32px;
  border-top: 5px solid #fdbe34;
  margin: auto;
  margin-bottom: 32px;
`;

export const SectionHeader = styled.h4`
  margin-bottom: 40px;
  color: #373838;
  font-size: 24px;
  font-weight: 400;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

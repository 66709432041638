import styled from "styled-components";

export const FormContainer = styled.div`
  min-height: 692px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: #fbfbfb;
`;

export const FormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    height: 80%;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

export const Form = styled.form`
  background: #fff;
  max-width: 600px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 32px 32px 60px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 400px) {
    padding: 32px;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const FormSubtitle = styled.h3`
  margin-bottom: 40px;
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const FormLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 20px;
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #373838;
`;

export const FormError = styled.span`
  display: inline-block;
  font-size: 14px;
  color: red;
`;

export const FormInput = styled.input`
  padding: 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  background: #f8f8f8;

  ::placeholder {
    color: #ababab;
  }

  :focus {
    outline: none;
    box-shadow: 0 0 0 2px #0d226b;
  }
`;

export const FormTextArea = styled.textarea`
  resize: none;
  padding: 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  background: #f8f8f8;

  ::placeholder {
    color: #ababab;
  }

  :focus {
    outline: none;
    box-shadow: 0 0 0 2px #0d226b;
  }
`;

export const FormButton = styled.button`
  background: #fff;
  padding: 16px 0;
  margin-top: 40px;
  border: none;
  border-radius: 4px;
  border: 2px solid #fdbe34;
  color: #0d226b;
  font-size: 20px;
  cursor: pointer;

  :hover {
    background-color: #0d226b;
    transition: all 0.3s ease-in-out;
    color: #fff;
    border: 2px solid #fff;
  }

  :disabled,
  [disabled] {
    border: 2px solid #f8f8f8;
    background-color: #f8f8f8;
    color: #949494;
    transition: all 0.3s ease-in-out;
  }
`;

export const Text = styled.span`
  text-align: center;
  margin-top: 40px;
  color: #373738;
  font-size: 14px;
`;

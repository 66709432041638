import React from "react";

import MapComponent from "../../map";
import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../common.elements";

import {
  HomeAccessContainer,
  InfoContainer,
  MapContainer,
  TextContainer,
  TopLine,
  Heading,
  NavLogoImg,
  NavLogoText,
  DescText,
  ButtonContainer,
  LinkButton,
  CopyButton,
  CopyIcon,
  CopyAlert,
} from "./home_access.elements";

import { OfficeData } from "../../../data/access_data";

import LogoImg from "../../../images/logo/logo-img.png";
import LogoTxt from "../../../images/logo/logo-text.png";

const gmapLink =
  "https://www.google.com/maps/place/%E3%88%B1%E5%AE%AE%E5%9F%8E%E9%81%8B%E8%BC%B8/@38.1998122,140.8467403,17z/data=!3m1!4b1!4m5!3m4!1s0x5f8a25d7274de51d:0x8bd9b24208a87525!8m2!3d38.199808!4d140.848929";

const AccessSection = () => {
  const data = OfficeData;
  const building = data.find((building) => building.id === 1);

  const copyAddress = () => {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = building.postcode + " " + building.address;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  return (
    <SectionContainer bgColor="#fbfbfb">
      <SectionWrapper>
        <SectionTitle>アクセスマップ</SectionTitle>
        <TitleDivider />
        <HomeAccessContainer>
          <InfoContainer>
            <TextContainer>
              <TopLine>【本社】</TopLine>
              <Heading>
                <NavLogoImg src={LogoImg} alt="logo-img" />
                <NavLogoText src={LogoTxt} alt="logo-txt" />
              </Heading>
              <DescText textColor="#373838" bold>
                〒{building.postcode}
                <br />
                {building.address}
              </DescText>
              <DescText textColor="#a49e9e">{building.guide}</DescText>
            </TextContainer>
            <ButtonContainer>
              <LinkButton target="_blank" href={gmapLink}>
                Google Map
              </LinkButton>
              <CopyButton onClick={() => copyAddress()}>
                <CopyIcon className="icon" size="24px" />
              </CopyButton>
              <CopyAlert></CopyAlert>
            </ButtonContainer>
          </InfoContainer>
          <MapContainer>
            <MapComponent
              lat={building.lat}
              lng={building.lng}
              name={building.name}
            />
          </MapContainer>
        </HomeAccessContainer>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default AccessSection;

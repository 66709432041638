import styled from "styled-components";
import { Link } from "react-scroll";

export const Narration = styled.div`
  width: 75%;
  text-align: center;
  line-height: 175%;
  margin-bottom: 24px;
  color: #373738;

  span {
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }

  @media screen and (max-width: 600px) {
    text-align: left;
    padding-left: 8px;
    width: 90%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const RecruitButton = styled(Link)`
  margin: 12px;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #fdbe34;
  width: 144px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #0d226b;

  :hover {
    background-color: #0d226b;
    transition: all 0.3s ease-in-out;
    color: #fff;
    border: 2px solid #fff;
  }
`;

export const RecruitLink = styled.a`
  margin: 12px;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #fdbe34;
  width: 144px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #0d226b;

  :hover {
    background-color: #0d226b;
    transition: all 0.3s ease-in-out;
    color: #fff;
    border: 2px solid #fff;
  }
`;

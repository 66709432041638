import React, { useEffect, useState } from "react";
import CustomSelect from "../custom_select";

import emailjs from "emailjs-com";
import { mailConfig as config } from "../../../config/mail_config";

import useForm from "../utility/useForm";
import validate from "../utility/validateForm";
import ReCAPTCHA from "react-google-recaptcha";

import { useTranslation } from "react-i18next";
import { i18n } from "../../../translations/i18n";

import japanFlagIcon from "../../../images/icon/japan.png";
import usaFlagIcon from "../../../images/icon/usa.png";

import { SectionTitle, TitleDivider } from "../../common.elements";
import {
  FormContainer,
  FormWrapper,
  FormHeader,
  FormContent,
  Form,
  FormError,
  FormItem,
  FormLabel,
  FormLabelContainer,
  FormInput,
  FormTextArea,
  FormButton,
  Text,
} from "./form.elements";

const ContactForm = ({ submitForm }) => {
  const sendEmail = () => {
    var service_id = config.service_id;
    var template_id = config.template_id;
    var user_id = config.user_id;

    setIsLoading(true);
    const params = {
      ...values,
      "g-recaptcha-response": captcha,
    };

    emailjs.send(service_id, template_id, params, user_id).then(
      (result) => {
        //console.log(result.text);
        submitForm();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const [isLoading, setIsLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const setCaptchaVal = (CaptchaVal) => {
    setCaptcha(CaptchaVal);
  };

  const { values, errors, handleSelect, handleChange, handleSubmit } = useForm(
    sendEmail,
    validate
  );

  const { t } = useTranslation();
  const [language, setLanguage] = useState("ja");
  const handleChangeLang = (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    return false;
  };

  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey(key + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const languageSelector = () => {
    return (
      <div style={{ alignSelf: "flex-end", marginBottom: "32px" }}>
        <input
          style={{ opacity: language === "ja" ? 1.0 : 0.4 }}
          type="image"
          src={japanFlagIcon}
          alt="ja_flag"
          value="ja"
          onClick={handleChangeLang}
        />
        <input
          style={{ opacity: language === "en" ? 1.0 : 0.4 }}
          type="image"
          src={usaFlagIcon}
          alt="us_flag"
          value="en"
          onClick={handleChangeLang}
        />
      </div>
    );
  };

  const options = [
    { value: "配送依頼", label: t("form_opt_delivery") },
    { value: "センター運営", label: t("form_opt_management") },
    { value: "3PL", label: t("form_opt_3pl") },
    { value: "倉庫", label: t("form_opt_warehouse") },
    { value: "倉内オペレーション", label: t("form_opt_operation") },
    { value: "その他", label: t("form_opt_other") },
  ];

  return (
    <FormContainer>
      <FormWrapper>
        <FormContent>
          <Form action="#" onSubmit={handleSubmit}>
            {languageSelector()}
            <FormHeader>
              <SectionTitle>{t("form_title")}</SectionTitle>
              <TitleDivider />
            </FormHeader>
            <FormItem>
              <FormLabelContainer>
                <FormLabel htmlFor="type">
                  {t("form_contact_type")}
                  {errors.type && (
                    <>
                      <FormError>・</FormError>
                      <FormError>{errors.type}</FormError>
                    </>
                  )}
                </FormLabel>
              </FormLabelContainer>
              <CustomSelect
                options={options}
                onChange={handleSelect}
                name="type"
                placeholder="お問合せ内容を選択してください"
              />
            </FormItem>
            <FormItem>
              <FormLabelContainer>
                <FormLabel htmlFor="company">
                  {t("form_company_name")}
                  {errors.company && (
                    <>
                      <FormError>・</FormError>
                      <FormError>{errors.company}</FormError>
                    </>
                  )}
                </FormLabel>
              </FormLabelContainer>
              <FormInput
                type="text"
                name="company"
                placeholder={t("form_placeholder_company")}
                value={values.company}
                onChange={handleChange}
              />
            </FormItem>
            <FormItem>
              <FormLabel htmlFor="affiliation">
                {t("form_department")}
              </FormLabel>
              <FormInput
                type="text"
                name="affiliation"
                placeholder={t("form_placeholder_department")}
                value={values.affiliation}
                onChange={handleChange}
              />
            </FormItem>
            <FormItem>
              <FormLabelContainer>
                <FormLabel htmlFor="fullname">
                  {t("form_name")}
                  {errors.fullname && (
                    <>
                      <FormError>・</FormError>
                      <FormError>{errors.fullname}</FormError>
                    </>
                  )}
                </FormLabel>
              </FormLabelContainer>
              <FormInput
                type="text"
                name="fullname"
                placeholder={t("form_placeholder_fullname")}
                value={values.fullname}
                onChange={handleChange}
              />
            </FormItem>
            <FormItem>
              <FormLabelContainer>
                <FormLabel htmlFor="phone">
                  {t("form_telephone")}
                  {errors.phone && (
                    <>
                      <FormError>・</FormError>
                      <FormError>{errors.phone}</FormError>
                    </>
                  )}
                </FormLabel>
              </FormLabelContainer>
              <FormInput
                type="tel"
                name="phone"
                placeholder="0123456789"
                value={values.phone}
                onChange={handleChange}
              />
            </FormItem>
            <FormItem>
              <FormLabelContainer>
                <FormLabel htmlFor="email">
                  {t("form_mail_address")}
                  {errors.email && (
                    <>
                      <FormError>・</FormError>
                      <FormError>{errors.email}</FormError>
                    </>
                  )}
                </FormLabel>
              </FormLabelContainer>
              <FormInput
                type="text"
                name="email"
                placeholder="miyagitarou@mail.com"
                value={values.email}
                onChange={handleChange}
              />
            </FormItem>
            <FormItem>
              <FormLabelContainer>
                <FormLabel htmlFor="content">
                  {t("form_contact_message")}
                  {errors.content && (
                    <>
                      <FormError>・</FormError>
                      <FormError>{errors.content}</FormError>
                    </>
                  )}
                </FormLabel>
              </FormLabelContainer>
              <FormTextArea
                name="content"
                rows="10"
                placeholder={t("form_placeholder_content")}
                value={values.content}
                onChange={handleChange}
              />
            </FormItem>
            <FormItem
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 auto",
              }}
            >
              <ReCAPTCHA
                key={key}
                sitekey="6LfeKIsaAAAAAIIFs-G3462sglY31Xmft9LDXyZE"
                hl={language}
                onChange={setCaptchaVal}
              />
            </FormItem>

            <FormButton type="submit" disabled={isLoading || !captcha}>
              {isLoading ? t("form_loading") : t("form_submit")}
            </FormButton>
            <Text>{t("form_disclaimer")}</Text>
          </Form>
        </FormContent>
      </FormWrapper>
    </FormContainer>
  );
};

export default ContactForm;

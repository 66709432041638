import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;

const TextWrapper = styled.div``;

const BigText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 72px;
  font-weight: bold;
  color: #0d226b;

  span {
    color: #fdbe34;
  }
`;

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px;

  div {
    line-height: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkButton = styled(Link)`
  margin: 12px;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #fdbe34;
  width: 144px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #0d226b;

  :hover {
    background-color: #0d226b;
    transition: all 0.3s ease-in-out;
    color: #fff;
    border: 2px solid #fff;
  }
`;

const NotFound = () => {
  return (
    <Container>
      <TextWrapper>
        <BigText>
          4<span>0</span>4
        </BigText>
        <Desc>
          <div>お探しのページが見つかりません</div>
          <div>移動または削除された可能性があります</div>
        </Desc>
        <ButtonWrapper>
          <LinkButton to="/">トップページ</LinkButton>
        </ButtonWrapper>
      </TextWrapper>
    </Container>
  );
};

export default NotFound;

import { i18n } from "../../../translations/i18n";

const validateForm = (values) => {
  let errors = {};

  // type validator
  if (!values.type) {
    errors.type = i18n.t("error_no_type");
  }

  // name validator
  if (!values.company.trim()) {
    errors.company = i18n.t("error_no_company");
  }

  // name validator
  if (!values.fullname.trim()) {
    errors.fullname = i18n.t("error_no_fullname");
  }

  // phone validator
  if (!values.phone) {
    errors.phone = i18n.t("error_no_phone");
  } else if (!/^[0-9]+$/i.test(values.phone)) {
    errors.phone = i18n.t("error_wrong_phone");
  }

  // email validator
  if (!values.email) {
    errors.email = i18n.t("error_no_email");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = i18n.t("error_wrong_email");
  }

  // content validator
  if (!values.content) {
    errors.content = i18n.t("error_no_content");
  }

  return errors;
};

export default validateForm;

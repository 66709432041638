import styled from "styled-components";
import { Link } from "react-router-dom";

export const SocialMedia = styled.section`
  width: 100%;
  background-color: #101522;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1080px;
  padding: 24px 40px;
  margin: auto;

  span {
    display: inline-block;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
    padding-top: 60px;
  }
`;

export const SocialLogo = styled(Link)`
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  width: 30%;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  } ;
`;

export const SocialLogoItem = styled.img`
  height: 24px;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  text-align: center;
  width: 40%;

  @media screen and (max-width: 768px) {
    width: 100%;
  } ;
`;

export const SocialIcon = styled.div`
  display: flex;
  top: 1.2rem;
  right: 1.5rem;
  font-size: 1.8rem;
  cursor: pointer;
  color: #fff;
  width: 30%;
  justify-content: flex-end;

  &:hover {
    color: #fdbe34;
    transition: 0.3s ease-out;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  } ;
`;

import miyagiAnshin from "../../images/company/cert-miyagi.jpg";
import gmarkCert from "../../images/company/cert-gmark.jpg";
import iso9001cert from "../../images/company/cert-iso9001-2015.jpg";
import iso39001cert from "../../images/company/cert-iso39001-2012.jpg";

export const CompanyCertificateData = [
  {
    name: "安心かつ適切な輸送・保管・管理のために",
    description: [
      "弊社は、いつもお客様の喜ぶことを考えて行動し、皆様に安心かつ適切な輸送・保管・管理に取り組んでいます。本社では、宮城県が進めている「みやぎ食の安全安心県民総参加運動」に参加し、宮城県の運送業では唯一、「みやぎ食の安心安全取組宣言」に平成16年より取り組んでいます（承認番号160208）。",
    ],
    scope: "",
    examiner: "",
    office: "",
    image: miyagiAnshin,
    alt: "miyagi_anshin_logo",
    link: "https://www.pref.miyagi.jp/site/annzennanshinn/sengen.html",
    linkname: "宮城県のホームページ",
  },
  {
    name: "ISO9001 品質への取り組み",
    scope: "食材及び加工食品を含む一般貨物運送業",
    examiner: "インターテック・サーティフィケーション株式会社",
    office: ["本社", "本社センター", "臨空センター"],
    image: iso9001cert,
    alt: "iso9001_logo",
  },
  {
    name: "ISO39001 道路交通安全の取り組み",
    description: [
      "弊社は、2016年6月に、ISO39001:2012の認証を本社にて取得いたしました。",
    ],
    scope: "食材及び加工食品を含む一般貨物運送業",
    examiner: "インターテック・サーティフィケーション株式会社",
    office: ["本社"],
    image: iso39001cert,
    alt: "iso309001_logo",
  },
  {
    name: "Gマーク制度（貨物自動車運送事業安全性評価事業）",
    description: [
      "Gマークとは、貨物自動車運送事業安全評価事業のことで、2003年7月に国土交通省が始めた制度です。",
      "具体的には、利用者が安全性の高い事業者を選びやすくする等の観点から、輸送の安全の確保に積極的に取り組んでいる事業所を認定する制度となっています。",
      "国が貨物自動車運送の秩序の確立のために指定した機関（全日本トラック協会）が38の評価項目を設定し、同機関内の安全性評価委員会において認定を行います。",
      "弊社では、全営業所にてGマークを取得しております。",
    ],
    scope: "",
    examiner: "",
    office: ["全営業所"],
    image: gmarkCert,
    alt: "gmark_logo",
  },
];

import React from "react";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../common.elements";

import {
  HistoryTable,
  TableItem,
  HistoryDate,
  HistoryDescription,
} from "./history.elements";

import { CompanyHistoryData } from "../../../data/company/history_data";

const HistoryInfo = () => {
  const historyData = CompanyHistoryData;
  const historyList = historyData.map((event, index) => {
    let _month;
    if (event.month > 0) _month = ".0" + event.month;
    if (event.month > 10) _month = "." + event.month;

    return (
      <TableItem key={index}>
        <HistoryDate>
          {event.year}
          {_month}
        </HistoryDate>
        <HistoryDescription>{event.event}</HistoryDescription>
      </TableItem>
    );
  });

  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitle>沿革</SectionTitle>
        <TitleDivider />
        <HistoryTable>{historyList}</HistoryTable>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default HistoryInfo;

export const TRANSLATIONS_JA = {
  form_ja: "日本語",
  form_en: "英語",

  form_contact_type: "お問合せの種類",
  form_company_name: "御社名",
  form_department: "所属部署（任意）",
  form_name: "氏名",
  form_telephone: "お電話番号",
  form_mail_address: "メールアドレス",
  form_contact_message: "お問合せの内容",

  form_opt_delivery: "配送依頼",
  form_opt_management: "センター運営",
  form_opt_3pl: "3PL",
  form_opt_warehouse: "倉庫",
  form_opt_operation: "倉内オペレーション",
  form_opt_other: "その他",

  form_placeholder_company: "○○○株式会社",
  form_placeholder_department: "○○業部",
  form_placeholder_fullname: "宮城　太郎",
  form_placeholder_content: "お問い合わせの詳細はこちらに書いてください。",

  form_title: "フォーム",
  form_submit: "送信",
  form_loading: "送信中。。。",
  form_disclaimer:
    "株式会社宮城運輸では、個人情報の保護に関する法令を順守をするとともに、プライバシーポリシーを基づき、お客様の個人情報を取り扱います。",

  error_no_type: "お問合せ種類を選択してください",
  error_no_company: "御社名を入力してください",
  error_no_fullname: "氏名を入力してください",
  error_no_phone: "お電話番号を入力してください",
  error_no_email: "メールアドレスを入力してください",
  error_no_content: "お問合せ内容を入力してください",

  error_wrong_phone: "無効の電話番号が入力されました",
  error_wrong_email: "無効のメールアドレスが入力されました",
};

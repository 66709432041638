import React from "react";

import CertificationItem from "./item/";
import { CompanyCertificateData } from "../../../data/company/certificate_data";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../common.elements";

import { CertificationTable } from "./certificate.element";

const CertificateList = () => {
  const certItems = CompanyCertificateData.map((certification, index) => {
    return <CertificationItem key={index} {...certification} />;
  });

  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitle>認証</SectionTitle>
        <TitleDivider />
        <CertificationTable>{certItems}</CertificationTable>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default CertificateList;

import React from "react";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../components/common.elements";
import ServiceHeader from "../../components/service/service_header";
import ServiceList from "../../components/service/service_list";
import { TechnologyServiceData } from "../../data/service/technologyData";

const Technology = () => {
  const content = TechnologyServiceData.map((tech, index) => {
    return <ServiceList key={index} index={index} {...tech} />;
  });

  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitle>IT事業</SectionTitle>
        <TitleDivider />
        <ServiceHeader />
        {content}
      </SectionWrapper>
    </SectionContainer>
  );
};

export default Technology;

import styled from "styled-components";
import { BiPaste } from "react-icons/bi";

export const HomeAccessContainer = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 24px 12px 12px;

  @media screen and (max-width: 768px) {
    padding: 24px;
    width: 100%;
  }
`;

export const MapContainer = styled.div`
  display: flex;
  width: 60%;
  padding-top: 40%;
  position: relative;
  box-shadow: 2px 2px 10px 2px rgba(164, 158, 158, 0.3),
    -2px -2px 10px 2px rgba(164, 158, 158, 0.3);

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 320px;
    margin: 0;
    padding: 0;
  }
`;

export const TextContainer = styled.div``;

export const TopLine = styled.p`
  color: #0d226b;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Heading = styled.div``;

export const NavLogoImg = styled.img`
  height: 44px;
  width: auto;

  @media screen and (max-width: 880px) {
    height: 36px;
  }
`;

export const NavLogoText = styled.img`
  height: 44px;
  width: auto;
  margin-left: 10px;

  @media screen and (max-width: 880px) {
    height: 36px;
  }
`;

export const DescText = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: ${(props) => (props.bold ? "bold" : "#normal")};
  color: ${(props) => props.textColor || "#373838"};
  max-width: 440px;
  margin: 20px 0px;

  @media screen and (max-width: 880px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px auto;

  @media screen and (max-width: 768px) {
    margin: 12px auto;
  }
`;

export const LinkButton = styled.a`
  border-radius: 5px;
  background: transparent;
  border: 2px solid #fdbe34;
  white-space: nowrap;
  padding: 10px 24px;
  margin: 12px;
  font-size: 16px;
  color: #0d226b;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  :hover {
    background: #0d226b;
    color: #fff;
    border: 2px solid #0d226b;
  }
`;

export const CopyButton = styled.div`
  display: flex;
  border-radius: 5px;
  border: 2px solid #fdbe34;
  white-space: nowrap;
  margin: 12px;
  height: 48px;
  width: 48px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  :hover {
    background: #0d226b;
    border: 2px solid #0d226b;
  }

  :hover .icon {
    color: #fff;
  }
`;

export const CopyIcon = styled(BiPaste)`
  display: flex;
  color: #0d226b;
  align-content: center;

  :hover {
    color: #fff;
  }
`;

export const CopyAlert = styled.div``;

import { useState, useEffect } from "react";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    type: "",
    company: "",
    affiliation: "",
    fullname: "",
    phone: "",
    email: "",
    content: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSelect = (opt) => {
    setValues({
      ...values,
      type: opt.value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setIsSubmitting(false);
      callback();
    } else if (Object.keys(errors).length > 0 && isSubmitting) {
      setIsSubmitting(false);
      window.scrollTo({
        top: 250,
        behavior: "smooth",
      });
    }
  }, [errors, isSubmitting, callback]);

  return { values, errors, handleSelect, handleChange, handleSubmit };
};

export default useForm;

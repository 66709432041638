import styled from "styled-components";

export const EthicTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  min-height: 1px;

  @media screen and (max-width: 864px) {
    padding: 0px 20px;
  }
`;

export const TableItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 20px auto;
  min-height: 1px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const EthicLabel = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  color: #0d226b;
  border-left: 5px solid #fdbe34;
  padding-left: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    align-self: flex-start;
    width: 100%;
  }
`;

export const EthicValue = styled.div`
  color: #373838;
  font-size: 16px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    align-self: flex-start;
    margin-top: 12px;
    width: 100%;
  }
`;

export const CredoTableItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 20px auto;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const CredoLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 0px 32px;

  font-size: 16px;
  font-weight: bold;

  color: #0d226b;
  background: #fbfbfb;

  span {
    display: inline-block;
  }

  @media screen and (max-width: 864px) {
    padding: 0px 16px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 0px;
    width: 100%;
    background: none;
  }
`;

export const CredoList = styled.ol`
  width: 75%;
  list-style-position: outside;
  padding-left: 36px;

  @media screen and (max-width: 768px) {
    padding-left: 24px;
    align-self: flex-start;
    margin-top: 12px;
    width: 100%;
  }
`;

export const CredoItem = styled.li`
  padding: 12px 0px;
`;

export const CredoItemTitle = styled.div`
  color: #0d226b;
  margin-bottom: 4px;
`;

export const CredoItemDesc = styled.div`
  color: #373838;
  line-height: 24px;
`;

export const CustomBullet = styled.div`
  display: none;
  color: #fdbe34;
  margin-right: 8px;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

export const SocialLogoItem = styled.img`
  width: 30%;
  height: auto;
  display: flex;
  align-self: center;
  justify-self: center;
  margin-bottom: 24px;
  min-height: 1px;

  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;

export const TableImg = styled.img`
  width: 75%;
  height: auto;
  display: flex;
  align-self: center;
  justify-self: center;
  min-height: 1px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

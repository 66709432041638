import React from "react";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
} from "./footer.elements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>企業情報</FooterLinkTitle>
              <FooterLink to="/company/outline">企業概要</FooterLink>
              <FooterLink to="/company/history">沿革</FooterLink>
              <FooterLink to="/company/certificate">認証</FooterLink>
              <FooterLink to="/services/ethic">企業理念</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>サービス</FooterLinkTitle>
              <FooterLink to="/services/delivery">配送</FooterLink>
              <FooterLink to="/services/warehouse">商品管理</FooterLink>
              <FooterLink to="/services/it-gyoumu">IT業務</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>アクセス</FooterLinkTitle>
              <FooterLink to="/access/1">本社センター</FooterLink>
              <FooterLink to="/access/2">臨空センター</FooterLink>
              <FooterLink to="/access/3">志和営業所</FooterLink>
              <FooterLink to="/access/4">茨城営業所</FooterLink>
              <FooterLink to="/access/5">小山営業所</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;

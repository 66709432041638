import styled from "styled-components";

export const CertContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 20px auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ItemTitle = styled.div`
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
  color: #0d226b;
  border-left: 5px solid #fdbe34;
  padding-left: 12px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 35%;
  margin-right: 24px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 24px 0px;
  }
`;

export const CertImage = styled.img`
  object-fit: scale-down;
  width: 100%;
  max-height: 300px;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 16px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 12px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
`;

export const InfoLabel = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  color: #ababab;
  margin-bottom: 6px;
`;

export const InfoValue = styled.div`
  line-height: 160%;
  color: #373838;
  font-size: 16px;
  font-weight: 500;

  span {
    display: inline-block;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

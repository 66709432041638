import styled from "styled-components";

export const CustomIconContainer = styled.div`
  text-align: center;
  width: 100%;
`;
export const NumberContainer = styled.div`
  font-size: ${({ size }) => size.concat("px")};
  font-weight: bold;
  letter-spacing: 4px;
  color: #0f4c81;

  @media screen and (max-width: 480px) {
    font-size: ${({ size }) =>
      ((parseInt(size) * 3) / 4).toString().concat("px")};
  }
`;
export const TypeContainer = styled.div`
  font-weight: bold;
  color: #373838;
`;

import styled from "styled-components";

export const OutlineTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-self: center;

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`;

export const TableItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 20px auto;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const OutlineLabel = styled.div`
  display: flex;
  width: 20%;
  font-size: 14px;
  justify-content: flex-start;
  color: #ababab;

  @media screen and (max-width: 768px) {
    align-self: flex-start;
    width: 100%;
  }
`;

export const OutlineValue = styled.div`
  color: #373838;
  width: 80%;
  font-size: 16px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    align-self: flex-start;
    margin-top: 12px;
    width: 100%;
  }
`;

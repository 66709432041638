import styled from "styled-components";

export const ListWrapper = styled.ul`
  color: #373838;
  margin-left: 20px;
`;

export const ListItem = styled.li`
  margin: 16px 0px;
  padding: 0px 8px;
`;

export const TechHeading = styled.h1`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.1.%;
  font-weight: 600;
  color: #0d226b;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    text-align: center;
  }
`;

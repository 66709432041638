import styled from "styled-components";

export const HeroContainer = styled.section`
  height: calc(100vh - 60px);
  max-height: 1100px;
  position: relative;
  overflow: hidden;
  background-color: #000;
`;

export const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const HeroSlide = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
`;

export const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
`;

export const HeroVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  object-fit: cover;
`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1080px;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 12px;

  @media screen and (max-width: 600px) {
    justify-content: space-between;
  }
`;

export const HeroHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    height: 100%;
  }
`;

export const HeroHeaderContent = styled.div`
  display: flex;
  flex-direction: row;

  h1 {
    font-size: 80px;
    font-weight: bold;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
    text-align: center;
    color: #fff;
  }

  @media screen and (max-width: 1000px) {
    h1 {
      font-size: 64px;
    }
  }

  @media screen and (max-width: 800px) {
    h1 {
      font-size: 48px;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    border: thick solid white;
    padding: 12px;

    h1 {
      font-size: 60px;
    }
  }
`;

export const HeroSubHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 160%;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);

  @media screen and (max-width: 600px) {
    position: flex;
    padding: 12px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 140%;
    width: 100%;
  }
`;

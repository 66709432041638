import ImageTechnology1 from "../../images/services/it/it-1.jpg";
import ImageTechnology2 from "../../images/services/it/it-2.jpg";

export const TechnologyServiceData = [
  {
    title: "IT課のこれまでの業務実績",
    points: [
      "業務用端末（PC）の修理、再生、スペックアップなどや、その他の電子機器の修理、検品など",
      "社内ネットワークの保守、管理（各種社内サーバー（ファイルサーバー、webサーバー、メールサーバー、プリンターサーバなど）の保守、管理）",
      "広報・宣伝用の企業ホームページの自社制作社内・社員向けホームページ、システム自社開発など",
    ],
    images: ImageTechnology1,
    alt: "DeliveryImage-1",
  },
  {
    title: "IT課として、今後視野に入れているビジネス展開",
    points: [
      "ホームページ制作・販売業務",
      "入庫や出庫などのハードウェアと連結した一括倉庫システムの開発や、入力に関するオートメーションプログラムシステムの自社開発、またそれらのノウハウの対外向けビジネス展開",
      "ハードウェアの修理や再生リサイクル販売業務",
    ],
    images: ImageTechnology2,
    alt: "DeliveryImage-2",
  },
];

import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

export const TopbarContainer = styled.aside`
  position: fixed;
  z-index: ${({ isOpen }) => (isOpen ? "999" : "-1")};
  width: 100%;
  height: 100%;
  background: #0d226b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
`;

export const CloseIcon = styled(FaTimes)`
  color: #fff;

  &:hover {
    color: #fdbe34;
    transition: 0.2s ease-in-out;
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const TopbarWrapper = styled.div`
  color: #fff;
`;

export const TopbarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const TopbarLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  list-style: none;
  transition: 0.2 ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  padding: 24px 0px;

  &:hover {
    color: #fdbe34;
    transition: 0.2s ease-in-out;
  }
`;

import styled from "styled-components";

export const BannerContainer = styled.section`
  height: 244px;
  position: relative;
  overflow: hidden;
  justify-content: center;
`;
export const BannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const BannerImage = styled.img`
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-animation: minimize 0.4s;
  animation: minimize 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  @-webkit-keyframes minimize {
    100% {
      -webkit-transform: scale(1, 1);
    }
  }

  @keyframes minimize {
    100% {
      transform: scale(1, 1);
    }
  }
`;

export const BannerOverlay = styled.div`
  background-color: rgba(13, 34, 107, 0.5);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const ContentContainer = styled.div`
  max-width: 1080px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const BannerContent = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1080px;
  width: 100%;
  height: 100%;
  padding: 3vw;

  h1 {
    color: #fff;
    font-size: clamp(2.5rem, 6vw, 3rem);
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align: center;
    margin-bottom: 1rem;
  }

  div {
    color: #fff;
    font-size: clamp(0.9rem, 2vw, 1.2rem);
    font-weight: 400;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align: center;
  }
`;

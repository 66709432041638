import styled from "styled-components";

export const CertificationTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-self: center;

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`;

export const OfficeData = [
  {
    id: 1,
    name: "本社センター",
    shortname: "本社",
    location: "宮城県、名取市",
    postcode: "981-1241",
    address: "宮城県名取市高舘熊野堂字岩口下46-1",
    tel: "(022) 381-2801",
    fax: "(022) 381-2803",
    description: [
      "東北6県を中心に店舗展開している大手外食チェーン店様(14社・計403店舗)の宮城、福島、山形、栃木、茨城方面の物流センター業務を行っております。品目としては、チルド品・冷凍品・常温品の食材・資材などです。入庫業務から店別仕分け、店舗配送までを一括で承っております。",
    ],
    vehicles: [
      {
        id: "lg_truck",
        name: "大型冷凍車",
        count: 8,
        unit: "台",
      },
      {
        id: "md__truck",
        name: "中型冷凍車",
        count: 35,
        unit: "台",
      },
      {
        id: "md_van",
        name: "中型バン",
        count: 14,
        unit: "台",
      },
    ],
    spaces: [
      { id: "reizouko", name: "冷蔵庫", count: "1762", unit: "m2" },
      { id: "reitouko", name: "冷凍庫", count: "816", unit: "m2" },
      { id: "doraiko", name: "ドライ庫", count: "106", unit: "m2" },
    ],
    lat: 38.19984140525088,
    lng: 140.84892259707198,
  },
  {
    id: 2,
    name: "臨空センター",
    shortname: "臨空",
    location: "宮城県、岩沼市",
    postcode: "989-2421",
    address: "宮城県岩沼市下野郷字中野馬場4-8",
    tel: "(0223) 23-1571",
    fax: "(0223) 23-1572",
    description: [
      "東北6県を中心に店舗展開している大手外食チェーン店様の物流センター業務を行っております。本社センター同様に、チルド品・冷凍品・常温品の食材・資材を承っております。",
    ],
    vehicles: [],
    spaces: [
      { id: "reizouko", name: "冷蔵庫", count: "2012", unit: "m2" },
      { id: "reitouko", name: "冷凍庫", count: "691", unit: "m2" },
      { id: "doraiko", name: "ドライ庫", count: "336", unit: "m2" },
    ],
    lat: 38.12968864996567,
    lng: 140.92025119216305,
  },
  {
    id: 3,
    name: "志和営業所(志和センター)",
    shortname: "志和",
    location: "岩手県、紫波郡",
    postcode: "028-3451",
    address: "岩手県紫波郡紫波町稲藤字升形44-5",
    tel: "(019) 681-6102",
    fax: "(019) 681-6103",
    description: [
      "大手外食チェーン店様(11社・計204店舗)の岩手、秋田、青森方面の物流センター業務を行っております。品目としては、チルド品・冷凍品・常温品の食材・資材などです。入庫業務から店別仕分け、店舗配送までを一括で承っております。",
    ],
    vehicles: [
      {
        id: "lg_truck",
        name: "大型冷凍車",
        count: 2,
        unit: "台",
      },
      {
        id: "md__truck",
        name: "中型冷凍車",
        count: 4,
        unit: "台",
      },
      {
        id: "md_van",
        name: "中型バン",
        count: 0,
        unit: "台",
      },
    ],
    spaces: [
      { id: "reizouko", name: "冷蔵庫", count: "550", unit: "m2" },
      { id: "reitouko", name: "冷凍庫", count: "263", unit: "m2" },
      { id: "doraiko", name: "ドライ庫", count: "51", unit: "m2" },
      { id: "doraiko2", name: "ドライ庫(別棟)", count: "339" },
    ],
    lat: 39.55286919223614,
    lng: 141.11206462630983,
  },
  {
    id: 4,
    name: "茨城営業所",
    shortname: "茨城",
    location: "茨城県、土浦市",
    postcode: "300-0007",
    address: "茨城県土浦市板谷7丁目627-17",
    tel: "(029) 875-8360",
    fax: "(029) 875-8361",
    description: [
      "主に大手外食チェーン様の工場・物流センター間の輸送を行っております。",
      "輸送の地域は関東圏内、関東〜東北等です。",
    ],
    vehicles: [
      {
        id: "lg_truck",
        name: "大型冷凍車",
        count: 5,
        unit: "台",
      },
      {
        id: "md__truck",
        name: "中型冷凍車",
        count: 2,
        unit: "台",
      },
      {
        id: "md_van",
        name: "中型バン",
        count: 0,
        unit: "台",
      },
    ],
    spaces: [],
    lat: 36.113470155139794,
    lng: 140.2108434973654,
  },
  {
    id: 5,
    name: "小山営業所",
    shortname: "小山",
    location: "栃木県、小山市",
    postcode: "323-0152",
    address: "栃木県小山市大字延島2705-1 北関東ロジスティクスセンターＡ棟",
    tel: "(0285) 38-9095",
    fax: "(0285) 38-9096",
    description: [
      "大手外食チェーン様(2社・計144店舗)の北関東方面の物流センター業務を行なっております。品目としては、チルド品・冷凍品・常温品の食品・資材などです。また、-45℃の超低温冷凍倉庫も備えております。",
    ],
    vehicles: [
      {
        id: "lg_truck",
        name: "大型冷凍車",
        count: 2,
        unit: "台",
      },
      {
        id: "md__truck",
        name: "中型冷凍車",
        count: 10,
        unit: "台",
      },
      {
        id: "md_van",
        name: "中型バン",
        count: 0,
        unit: "台",
      },
    ],
    spaces: [
      { id: "reizouko", name: "冷蔵庫", count: "964", unit: "m2" },
      { id: "reitouko", name: "冷凍庫", count: "480", unit: "m2" },
      { id: "choreitou", name: "超冷凍", count: "55", unit: "m2" },
      { id: "doraiko", name: "ドライ庫", count: "1499", unit: "m2" },
    ],
    lat: 36.34902482832999,
    lng: 139.87922062300726,
  },
];

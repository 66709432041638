import React from "react";
import {
  HeroContainer,
  HeroWrapper,
  HeroSlide,
  HeroSlider,
  HeroVideo,
  HeroContent,
  HeroHeaderContainer,
  HeroHeaderContent,
  HeroSubHeaderContainer,
} from "./hero.elements";

const HeroSection = ({ data }) => {
  return (
    <HeroContainer>
      <HeroWrapper>
        <HeroSlide>
          <HeroSlider>
            <HeroVideo playsInline autoPlay loop muted>
              <source src={data.video} type="video/mp4" />
            </HeroVideo>
            <HeroContent>
              <HeroHeaderContainer>
                <HeroHeaderContent>
                  {data.title.map((motto, index) => {
                    return <h1 key={index}>{motto}</h1>;
                  })}
                </HeroHeaderContent>
              </HeroHeaderContainer>
              <HeroSubHeaderContainer>
                {data.text.map((line, index) => {
                  return <div key={index}>{line}</div>;
                })}
              </HeroSubHeaderContainer>
            </HeroContent>
          </HeroSlider>
        </HeroSlide>
      </HeroWrapper>
    </HeroContainer>
  );
};

export default HeroSection;

import React from "react";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../common.elements";
import {
  Narration,
  ButtonContainer,
  RecruitLink,
} from "./recruit_header.elements";

const exLink = "https://www.talent-clip.jp/miyagiunyu/";

const RecruitHeader = () => {
  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitle>当社で働くメリット</SectionTitle>
        <TitleDivider />
        <Narration>
          <span>若いうちはドライバー、</span>
          <span>歳を取ったら</span>
          <span>倉庫作業や</span>
          <span>短時間勤務など、</span>
          <span>年齢に合わせた</span>
          <span>働き方が可能です。</span>
          <span>当社の主な事業である食品総合物流は、</span>
          <span>継続性が高く、</span>
          <span>非常に安定しており、</span>
          <span>リストラはほとんどなく、</span>
          <span>全ての世代に合った仕事メニューが用意できます。</span>
        </Narration>
        <Narration>
          <span>評価連動給与制度</span>
          <span>・定年再雇用・</span>
          <span>各種資格取得制度</span>
          <span>あり。</span>
        </Narration>
        <ButtonContainer>
          <RecruitLink target="_blank" href={exLink}>
            求人情報を見る
          </RecruitLink>
        </ButtonContainer>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default RecruitHeader;

import React from 'react'
import HistoryInfo from '../../components/company/history'

const History = () => {
  return (
    <HistoryInfo />
  )
}

export default History

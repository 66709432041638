import React from "react";

import Slider from "../../../slider";

import {
  ServiceContainer,
  ServiceWrapper,
  TextContainer,
  TextWrapper,
  TopLine,
  Heading,
  DescText,
  ImageContainer,
  ImageWrapper,
  BtnContainer,
  BtnWrapper,
  LinkButton,
} from "./service_content.elements";

const ServiceContent = ({
  id,
  imgStart,
  topLine,
  headline,
  description,
  images,
  alt,
}) => {
  return (
    <ServiceContainer id={id}>
      <ServiceWrapper imgStart={imgStart}>
        <ImageContainer>
          <ImageWrapper>
            <Slider slides={images} />
          </ImageWrapper>
        </ImageContainer>
        <TextContainer>
          <TextWrapper>
            <TopLine>{topLine}</TopLine>
            <Heading>{headline}</Heading>
            <DescText>{description}</DescText>
          </TextWrapper>
          <BtnContainer>
            <BtnWrapper>
              <LinkButton to={`/services/${id}`}>詳しく見る</LinkButton>
            </BtnWrapper>
          </BtnContainer>
        </TextContainer>
      </ServiceWrapper>
    </ServiceContainer>
  );
};

export default ServiceContent;

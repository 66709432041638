import ImageDelivery1 from "../../images/services/delivery/delivery-1.jpg";
import ImageDelivery2 from "../../images/services/delivery/delivery-2.jpg";
import ImageDelivery3 from "../../images/services/delivery/delivery-3.jpg";

export const DeliveryServiceData = [
  {
    title: "荷主様への取り組み",
    points:
      "同一方面による共同配送という形で、荷主様の商品、食材を混載し、お客様のニーズにマッチした配送時間を厳守できるように運行計画を立てております。同一方面への配送をお引き受けすることで、荷主様へは、物流費削減という形でコストメリットの還元をさせていただいております。",
    images: ImageDelivery1,
    alt: "DeliveryImage-1",
  },
  {
    title: "環境問題への取り組み",
    points:
      "効率良い配車組みにより、運行車両をミニマムに、お荷物をマキシマムに運ばせていただくことで、環境にやさしい運行を目指しております。",
    images: ImageDelivery2,
    alt: "DeliveryImage-2",
  },
  {
    title: "サービス範囲",
    points:
      "配送エリア: 日本全国各地の配送を承ります。配送先業種: レストランチェーン、量販物流センター、学校・売店、販売店等",
    images: ImageDelivery3,
    alt: "DeliveryImage-3",
  },
];

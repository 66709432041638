import React from "react";
import Select from "react-select";

const customStyles = {
  container: (styles) => ({
    ...styles,
  }),
  control: (styles, state) => ({
    ...styles,
    padding: "7px",
    border: "none",
    borderRadius: "4px",
    fontSize: "14px",
    background: "#f8f8f8",
    cursor: "pointer",
    boxShadow: state.isFocused ? "0 0 0 2px #0d226b;" : "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#ababab",
  }),
  group: (styles) => ({
    ...styles,
    margin: "0px",
    padding: "0px",
  }),
  groupHeading: (styles) => ({
    ...styles,
    margin: "0px",
    background: "#fbfbfb",
    color: "#373838",
    padding: "8px 12px",
    borderTop: "1px solid #a49e9e",
    borderBottom: "1px solid #a49e9e",
    fontSize: "16px",
  }),
  option: (styles, state) => ({
    ...styles,
    fontSize: "14px",
    color: state.isSelected ? "#0f4c81" : "#373838",
    fontWeight: state.isSelected ? "bold" : "normal",
    backgroundColor: "#ffff",

    "&:hover": {
      backgroundColor: "#ebf2ff",
    },
  }),
};

const CustomSelect = ({ options, placeholder, onChange, defaultValue }) => {
  return (
    <Select
      styles={customStyles}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      isSearchable={false}
      defaultValue={{ label: defaultValue, value: defaultValue }}
    />
  );
};

export default CustomSelect;

import React from "react";
import { useRouteMatch } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

import {
  SSNavContainer,
  SSNavWrapper,
  SSNavLink,
  ArrowIcon,
} from "./smallnav.elements";

const SmallNav = ({ data }) => {
  const { url } = useRouteMatch();

  const Links = data.map((item, index) => {
    return (
      <SSNavLink key={index} to={`${url}/${item.urlpath}`}>
        {item.linkname}
        <ArrowIcon>
          <FaChevronRight className="arrow" />
        </ArrowIcon>
      </SSNavLink>
    );
  });

  return (
    <SSNavContainer>
      <SSNavWrapper>{Links}</SSNavWrapper>
    </SSNavContainer>
  );
};

export default SmallNav;

import React from "react";
import { FaChevronRight } from "react-icons/fa";

import Accordion from "../../accordion";
import {
  SmallFooterContainer,
  FooterButton,
  FooterIcon,
} from "./smallfooter.elements";

const company_opt = [
  { val: "企業概要", path: "/company/outline" },
  { val: "沿革", path: "/company/history" },
  { val: "認証", path: "/company/certificate" },
  { val: "企業理念", path: "/company/ethic" },
];

const services_opt = [
  { val: "配送", path: "/services/delivery" },
  { val: "商品管理", path: "/services/warehouse" },
  { val: "IT業務", path: "/services/it-gyoumu" },
];

const access_opt = [
  { val: "本社センター", path: "/access/1" },
  { val: "臨空センター", path: "/access/2" },
  { val: "志和営業所", path: "/access/3" },
  { val: "茨城営業所", path: "/access/4" },
  { val: "小山営業所", path: "/access/5" },
];

const SmallFooter = () => {
  return (
    <SmallFooterContainer>
      <FooterButton exact={true} to="/">
        トップページ
        <FooterIcon>
          <FaChevronRight />
        </FooterIcon>
      </FooterButton>
      <Accordion title="企業情報" options={company_opt} />
      <Accordion title="サービス" options={services_opt}></Accordion>
      <Accordion title="アクセス" options={access_opt}></Accordion>
    </SmallFooterContainer>
  );
};

export default SmallFooter;

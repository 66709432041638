import React from "react";

import {
  SectionContainer,
  SectionWrapper,
  SectionTitle,
  TitleDivider,
} from "../../common.elements";
import ServiceContent from "./content";

import {
  ServiceObjectDelivery,
  ServiceObjectWarehouse,
} from "../../../data/home/home_service_data";

const ServiceSection = () => {
  return (
    <SectionContainer bgColor="#fff">
      <SectionWrapper>
        <SectionTitle>「サービス」</SectionTitle>
        <TitleDivider />
        <ServiceContent {...ServiceObjectDelivery} />
        <ServiceContent {...ServiceObjectWarehouse} />
      </SectionWrapper>
    </SectionContainer>
  );
};

export default ServiceSection;
